import * as React from "react";
import Gallery from "react-photo-gallery";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

import Layout from "../components/layout.js";

import * as imgs from "../images/gallery";

const photos = [
  { src: imgs.union_4x3, width: 4, height: 3 },
  { src: imgs.headshot_4x3, width: 4, height: 3 },
  { src: imgs.bets_1x1, width: 1, height: 1 },
  { src: imgs.barn_3x4, width: 3, height: 4 },
  { src: imgs.starman_3x4, width: 3, height: 4 },
  { src: imgs.fall_1x1, width: 1, height: 1 },
  { src: imgs.anima_4x3, width: 4, height: 3 },
  { src: imgs.bets_3x2, width: 3, height: 2 },
  { src: imgs.dogs_4x3, width: 4, height: 3 },
  { src: imgs.another_3x2, width: 3, height: 2 },
  { src: imgs.dca_4x3, width: 4, height: 3 },
  { src: imgs.bets_4x3, width: 4, height: 3 },
  { src: imgs.another_4x3, width: 4, height: 3 },
  { src: imgs.nlog_3x4, width: 3, height: 4 },
  { src: imgs.dogs_3x2, width: 3, height: 2 },
  { src: imgs.diner_4x3, width: 4, height: 3 },
  { src: imgs.clown_3x4, width: 3, height: 4 },
  { src: imgs.pranav_3x4, width: 3, height: 4 },
];

<Gallery photos={photos} />;

const GalleryPage = () => {
  const [index, setIndex] = React.useState(0);
  const [isOpen, setIsOpen] = React.useState(false);

  const openLightbox = (event, { photo, index }) => {
    setIndex(index);
    setIsOpen(true);
  };

  const closeLightbox = () => {
    setIndex(0);
    setIsOpen(false);
  };

  return (
    <Layout title="lars montanaro | gallery">
      <Gallery photos={photos} onClick={openLightbox} />
      {isOpen && (
        <Lightbox
          mainSrc={photos[index].src}
          nextSrc={photos[(index + 1) % photos.length].src}
          prevSrc={photos[(index + photos.length - 1) % photos.length].src}
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setIndex((index + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() => setIndex((index + 1) % photos.length)}
        />
      )}
    </Layout>
  );
};

export const Head = () => <title>Lars Montanaro</title>;

export default GalleryPage;
