import * as React from "react";

import Header from "./header.js";

const Layout = ({ title, image, children, showNav = true }) => {
  return (
    <div>
      {/* global width wrapper for layouts */}
      <div className="mx-auto max-w-[1000px]">
        <div className="mx-4">
          {showNav && <Header />}
          {image}
          <h1 className="my-2">{title}</h1>
          <main>{children}</main>
          <div className="my-[25px] mx-4">© 2023 Lars Montanaro</div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
